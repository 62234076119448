import CarrierService from '@/services-http/contract/CarrierService';
import { mapGetters, mapMutations } from 'vuex';

export default {
  data: () => ({
    insuranceCarries: [],
  }),

  methods: {
    ...mapMutations({
      setInsuranceCarriers: "insurance-carrier/setInsuranceCarriers",
    }),

    async getInsuranceCarriers() {
      const carrierService = new CarrierService();
      this.loadingInsuranceCarriers = true;
      await carrierService.FindAll().then((response) => {
        if (response && response.data) {
          this.insuranceCarriers = response.data;
          this.insuranceCarriers.sort((a, b) => (a.xipp_commercial_name > b.xipp_commercial_name ? 1 : -1));
          this.setInsuranceCarriers([...this.insuranceCarriers]);
          this.loadingInsuranceCarriers = false;
        }
      }).catch(() => {
        this.loadingInsuranceCarriers = false;
      });
    },
    getInsuranceCarrierNameById(id) {
      let insCarriers = [];

      if (Array.isArray(this.insuranceCarriers) && this.insuranceCarriers.length > 0) {
        insCarriers = this.insuranceCarriers;
      } else if (Array.isArray(this.carriers) && this.carriers.length > 0) {
        insCarriers = this.carriers;
      }

      const carrierFound = insCarriers.find((carrier) => carrier.id === id);
      if (carrierFound) {
        return carrierFound.xipp_commercial_name;
      }
      return null;
    },
  },

  computed: {
    ...mapGetters({
      insCarriers: "insurance-carrier/insuranceCarriers",
    }),
  }
};
